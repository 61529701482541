import { Layout } from 'containers/';
import {
  getProfile,
  getListings,
  getAchievement,
  getListingSold
} from '@services/marketing-profile';
import { MarketingProfile } from 'components/';

import NotFound from 'components/NotFound';

const BASE_URL = process.env.API;

export async function getServerSideProps(context) {
  try {
    const { id } = context.query;

    const responseProfile = await getProfile(id);

    const responseListingMarketing = await getListings(`?user_id=${id}`);
    
    const responseListingKantor = await getListings(`?user_id=&company_id=${responseProfile?.data?.company?.id}`);
    const responseAchievement = await getAchievement(id);
    const responseListingSold = await getListingSold(id);

    return {
      props: {
        dataProfile: responseProfile.data,
        dataListingMarketing: responseListingMarketing.data,
        dataAchievement: responseAchievement.data,
        dataListingSold: responseListingSold.data,
        dataListingKantor: responseListingKantor.data
      },
    };
  } catch (error) {
    console.log(error)
    return {
      props: {
        dataProfile: null,
      },
    };
  }
}

const MarketingProfileLayout = ({ dataProfile, dataListingMarketing, dataAchievement, dataListingSold, dataListingKantor }) => {
  if (!dataProfile) {
    return <NotFound />;
  }
  return (
    <Layout title="Marketing Profile">
      <MarketingProfile
        dataProfile={dataProfile}
        dataListingMarketing={dataListingMarketing}
        dataAchievement={dataAchievement}
        dataListingSold={dataListingSold}
        dataListingKantor={dataListingKantor}
      />
    </Layout>
  );
};

export default MarketingProfileLayout;
